import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';

import MentionList from './MentionList.vue';

export default {
  items: () => {
    return [
        {label: 'Sample', value: 'lesamplea'}
    ];
  },

  render: () => {
    let component;
    let popup;

    return {
      onStart: props => {
        component = new VueRenderer(MentionList, {
            parent: this,
            propsData: props,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy(component.element, {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.getElementsByClassName('ProseMirror')?.[0].parentElement?.parentElement,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom',
            theme: 'light',
            delay: [200, 0],
          });
      },

      onUpdate(props) {
        component.updateProps(props);
        popup?.setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        // Permet la navigation dans les suggestions avec les flèches
        if (component.ref?.onKeyDown(props)) {
            return true;
        }

        // Permet la validation avec Entrée
        if (props.event.key === 'Enter') {
            props.event.preventDefault();
            component.ref?.select();
            return true;
        }

        return false;
      },

      onExit() { 
        if (popup) popup?.destroy();
        component.destroy();
      },
    };
  }
};