var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "user-avatar",
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      "div",
      _vm.$attrs,
      false
    ),
    [
      _c("img", {
        staticClass: "rounded-circle",
        attrs: {
          id: "user-avatar-" + _vm._uid,
          width: _vm.width,
          height: _vm.width,
          src: _vm.userAvatar,
          title: _vm.userName
        }
      }),
      _vm.userId
        ? _c(
            "b-tooltip",
            {
              attrs: {
                target: "user-avatar-" + _vm._uid,
                placement: "bottom",
                triggers: "hover"
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.userName) + "\n    ")]
          )
        : _vm._e(),
      _vm.showLabel
        ? _c("div", { staticClass: "user-body ml-1" }, [
            _vm._v("\n        " + _vm._s(_vm.userName) + "\n    ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }