import Vue from 'vue';
import store from "@/store";
import _ from 'lodash';

const def = x => typeof x !== 'undefined';

/**
 * Fonctionnal reverse function
 * @param array to reverse
 * @returns {Array} reverse array
 */
export const reverse = ([x, ...xs]) => def(x) ? [...reverse(xs), x] : [];

export const groupBy = function (array, prop) {
    return array.reduce(function (groups, item) {
        const val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
    }, {});
};

export const paginator = function (perPage = 10, options = {}) {
    const params = {
        perPage,
        fields: options.fields || [],
        fieldsSettings: {},
        isClientPagination: options.isClientPagination || false
    };
    params.defaultPerPage = params.perPage;
    if (options.key) {
        const settings = store.getters['tools/getTableConfig'](options.key);
        if (settings?.pagination) params.perPage = settings.pagination;
        if (settings?.fields) params.fieldsSettings = JSON.parse(JSON.stringify(settings.fields));
    }
    for (const key of Object.keys(params.fieldsSettings)) {
        const field = params.fields.find(f => f.key === key);
        if (field) {
            if (!field.settings) Vue.set(field, 'settings', {});
            field.settings = {
                ...field.settings,
                ...params.fieldsSettings[key]
            };
            Vue.set(field.settings, 'visible', params.fieldsSettings[key].visible);
        }
    }
    const context = {
        defaultFields: [],
        persistKey: options.key,
        perPage: params.perPage,
        defaultPerPage: params.defaultPerPage,
        currentPage: 1,
        isClientPagination: params.isClientPagination,
        total: 0,
        data: [],
        totalPages: 0,
        sortDataClient: !!options.sortDataClient,
        sortProp: null,
        sortOrder: null,
        q: null,
        display: null,
        fields: params.fields,
        get visibleFields() {
            return context.fields.filter(f => f?.settings?.visible !== false);
        },
        get visibleFieldsAg() {
            const sortedFields = context.fields.filter(f => f?.settings?.visible !== false);
            sortedFields.sort((a, b) => {
                return (typeof a?.settings?.order === 'number'? a.settings.order : 99999) - (typeof b?.settings?.order === 'number'? b.settings.order : 99999);
            });
            return sortedFields.map(f => {
                const sortable = f.sortable ?? true;
                let sort = null;
                if (sortable && context.sortProp === f.key) {
                    sort = context.sortOrder === 'ascending' ? 'asc' : 'desc';
                }
                return {
                    headerName: f.label,
                    field: f.key,
                    type: f.type,
                    headerTooltip: f.description,
                    resizable: f.resizable,
                    pinned: typeof f.settings?.pinned !== 'undefined' ? f.settings?.pinned : f.pinned,
                    lockPinned: f.lockPinned,
                    width: !f.lockPinned ? f.settings?.width || f.width : f.width,
                    minWidth: f.minWidth,
                    flex: f.settings?.width ? null : f.flex, // No flex when size defined
                    checkboxSelection: f.checkboxSelection,
                    suppressMenu: f.suppressMenu,
                    sortable,
                    sort,
                    comparator: f.comparator || f.sortByFormatted,
                    valueFormatter: f.formatter || f.valueFormatter,
                    cellClass: f.class,
                    cellRenderer: f.cellRenderer,
                    cellRendererParams: f.cellRendererParams,
                    editable: f.editable,
                    cellEditor: f.cellEditor,
                    cellEditorPopup: f.cellEditorPopup,
                    cellEditorParams: f.cellEditorParams
                };
            });
        },
        get fieldsSettings() {
            return params.fieldsSettings;
        },
        sort: (prop, order, data) => {
            if (!['ascending', 'descending'].includes(order)) throw `Order must be one of ascending, descending`;
            context.sortProp = prop;
            context.sortOrder = order;
            const currentData = data || context.data;
            // Sort data
            if (context.sortDataClient && currentData?.length) {
                const sortFn = context.sortProp ? context.fields.find(f => f?.key === context.sortProp)?.comparator : null;
                if (context.sortProp) {
                    if (sortFn) {
                        currentData.sort((a, b) => {
                            const resSort = sortFn(a[context.sortProp], b[context.sortProp], a, b);
                            if (context.sortOrder === 'ascending') {
                                return resSort;
                            }
                            else {
                                return -resSort;
                            }
                        });
                    }
                    else {
                        currentData.sort((a, b) => a[context.sortProp] > b[context.sortProp] ? (context.sortOrder === 'ascending' ? 1 : -1) : (context.sortOrder === 'ascending' ? -1 : 1));
                    }
                }
                else {
                    currentData.sort();
                }
            }
            return currentData;
        },
        search: (term) => {
            context.q = term;
        },
        get paginatedData() {
            if (!context.data?.length) return [];
            let offset = (context.currentPage - 1) * context.perPage;
            return context.data.slice(offset).slice(0, context.perPage);
        },
        filters: {},
        paginate: (items) => {
            let offset = (context.currentPage - 1) * context.perPage,
                paginatedItems = items.slice(offset).slice(0, context.perPage),
                totalPages = Math.ceil(items.length / context.perPage);
  
            return {
                page: context.currentPage,
                perPage: perPage,
                prePage: context.currentPage - 1 ? context.currentPage - 1 : null,
                nextPage: (totalPages > context.currentPage) ? context.currentPage + 1 : null,
                total: items.length,
                totalPages,
                data: paginatedItems
            };
        },
        setFields(fields) {
            context.defaultFields = _.cloneDeep(fields);
            context.fields = fields;
            for (const key of Object.keys(params.fieldsSettings)) {
            
                const field = context.fields.find(f => f.key === key);
                if (field) {
                    if (!field.settings) Vue.set(field, 'settings', {});
                    field.settings = {
                        ...field.settings,
                        ...params.fieldsSettings[key]
                    };
                    Vue.set(field.settings, 'visible', params.fieldsSettings[key].visible);
                }
            }
        },
        setOption({ perPage, display, field, fields }) {
            if (display) {
                context.display = display;
            }
            if (field) {
                params.fieldsSettings[field.key] = {
                    visible: field.visible
                };
            }
            if (fields) {
                params.fieldsSettings = {
                    ...params.fieldsSettings,
                    ...fields
                };
                for (const key of Object.keys(fields)) {
                    const field = context.fields.find(f => f.key === key);
                    if (field) {
                        if (!field.settings) Vue.set(field, 'settings', {});
                        Vue.set(field.settings, 'visible', fields[key].visible);
                    }
                }
            }
            if (perPage) {
                context.perPage = perPage;
            }
            if (context.persistKey) store.dispatch("tools/updateTableSettings", {
                value: { pagination: perPage || context.perPage, fields: JSON.parse(JSON.stringify(params.fieldsSettings)) }, key: context.persistKey,
            });
        },
        saveFieldsOrder(fieldsConfig) {
            if (!fieldsConfig?.length) return;

            let order = 0;
            for (const conf of fieldsConfig) {
                if (!params.fieldsSettings[conf.colId]) params.fieldsSettings[conf.colId] = {};
                const settings = params.fieldsSettings[conf.colId];
                if (settings) {
                    settings.order = order;
                    settings.pinned = conf.pinned;
                    settings.pivot = conf.pivot;
                    settings.width = conf.width;
                }
                const field = context.fields.find(f => f.key === conf.colId);
                if (field) {
                    if (!field.settings) field.settings = {};
                    field.settings.order = order;
                    field.settings.pinned = conf.pinned;
                    field.settings.pivot = conf.pivot;
                    field.settings.width = conf.width;
                }
                order++;
                
            }
            if (context.persistKey) store.dispatch("tools/updateTableSettings", {
                value: { pagination: context.perPage, fields: JSON.parse(JSON.stringify(params.fieldsSettings)) }, key: context.persistKey,
            });
        },
        resetSettings() {
            try {
                let count = 0;
                for (let field of context.fields) {
                    const defaultField = context.defaultFields.find(f => f.key === field.key);
                    Vue.delete(field.settings, 'pivot');
                    Vue.delete(field.settings, 'width');
                    Vue.delete(field.settings, 'pinned');
                    if (defaultField?.settings?.visible !== undefined) {
                        Vue.set(field.settings, 'visible', defaultField.settings.visible);
                    } else {
                        Vue.set(field.settings, 'visible', true);
                    }
                    Vue.set(field.settings, 'order', count);
                    Vue.set(params.fieldsSettings, `${field.key}`, field.settings);
                    count++;
                }
                
                Vue.set(context, 'perPage', context.defaultPerPage);

                store.dispatch("tools/updateTableSettings", {
                    value: { pagination: context.perPage, fields: JSON.parse(JSON.stringify(params.fieldsSettings)) }, key: context.persistKey,
                });
            }
            catch (e) {
                console.error(e); // eslint-disable-line
            }
        

        },
      set({ data, total }) {
        context.data = data;
        context.total = total;
        context.totalPages = Math.ceil(total / context.perPage);
        if (context.sortDataClient && context.sortProp) {
            context.sort(context.sortProp, context.sortOrder);
        }
      },
        toCtx() {
            if (context.isClientPagination) {
                return {
                    sort: {
                        prop: context.sortProp,
                        order: context.sortOrder
                    },
                    fields: context.fields,
                    filters: context.filters,
                    q: context.q,
                    display: context.display
                };
            }
            return {
                page: context.currentPage,
                size: context.perPage,
                sort: {
                    prop: context.sortProp,
                    order: context.sortOrder
                },
                fields: context.fields,
                filters: context.filters,
                q: context.q,
                display: context.display
            };
      }
    };
    return context;
};