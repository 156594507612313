<template>
    <div>
        <div
        class="infinite-scroll-container"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10">
            <div v-for="notification in notifications" :key="notification.id">
                <notification-list-item :notification="notification" :show.sync="showed" @disabled-popover="handleDisabledPopover"/>
            </div>
            <div class="align-self-center my-2" v-if="busy">
                <b-spinner class="mx-auto" variant="primary"></b-spinner>
            </div>
        </div>
        
        <div v-if="notifications.length === 0" class="no-notification mt-3 p-3">
            <b-icon icon="bell-slash-fill" />
            {{ noNotificationMessage }}
        </div>
    </div>
</template>

<script>
import NotificationListItem from './NotificationListItem.vue';

export default {
    components: {
        NotificationListItem,
    },
    data() {
        return {
            showed: false,
            busy: false
        };
    },
    props: {
        notificationType: {
            type: String,
        },
        notifications: {
            type: Array,
            default: () => []
        }, 
        noNotificationMessage: {
            type: String,
            default: 'No notifications'
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        allLoaded() {
            return this.$store.getters['notifications/isAllLoadedNotifications'](this.notificationType);
        }
    },
    methods: {
        async loadMore() {
            if (this.busy || this.allLoaded) return;
  
            this.busy = true;
            await this.$store.dispatch('notifications/fetchNotifications', { notificationsType: this.notificationType });
            this.busy = false;
        },
        handleDisabledPopover() {
            this.$emit('disabled-popover');
        }
    },
    watch: {
        showed(value) {
            this.$emit('update:show', value);
        }
    }

};
</script>

<style scoped lang="scss">

.infinite-scroll-container {
    overflow-y: auto;
    max-height: 500px;
    display: flex;
    flex-direction: column;
}

.no-notification {
    background-color: rgba(0,0,0,0.015);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    font-size: 1.5rem;
    color: rgba(0,0,0,0.3);
    & > .b-icon {
       font-size: 2rem;
    }
}
</style>
