<template>
    <div>
        <div v-if="items.length > 0" class="scrollable-list">
            <div
                v-for=" (item, index) in items"
                :key="item.value"
            >
                <div 
                    class="d-flex align-items-center justify-center w-100 px-3 py-2 -pointer mention-list-item" 
                    :class="{ 'highlight': index === selectedIndex }"
                    @click="selectItem(index)"
                    @mouseenter="selectedIndex = index"
                > 
                    <user-avatar
                        width="24"
                        :userId="item.value"
                        empty="/faces/question.png"
                        class="mr-2"
                    />
                    {{ item.label }}
                </div>
            </div>
        </div>
        <div v-else >
            {{ $t("commons.editor.mention.nouser") }}
        </div>
    </div>
</template>
  
<script>
  import UserAvatar from '@/modules/commons/components/UserAvatar';

  export default {
    components: {
        UserAvatar,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
  
      command: {
        type: Function,
        required: true,
      },
    },
  
    data() {
      return {
        selectedIndex: 0,
        selectedItem: '',  
      };
    },
  
    watch: {
      items() {
        this.selectedIndex = 0;
      }
    },
    methods: {
      onKeyDown({ event }) {
        if (event.key === 'ArrowUp') {
          this.upHandler();
          return true;
        }
  
        if (event.key === 'ArrowDown') {
          this.downHandler();
          return true;
        }
  
        if (event.key === 'Enter') {
          this.enterHandler();
          return true;
        }
  
        return false;
      },
        upHandler() {
            this.selectedIndex =
                (this.selectedIndex + this.items.length - 1) % this.items.length;
            this.scrollToSelected();
        },
        downHandler() {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
            this.scrollToSelected();
        },
        scrollToSelected() {
            this.$nextTick(() => {
                const selectedElement = this.$el.querySelectorAll(
                '.mention-list-item'
                )[this.selectedIndex];
                if (selectedElement) {
                selectedElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
                }
            });
        },
        enterHandler() {
            this.selectItem(this.selectedIndex);
        },
        findIndexByItem(item) {
            return this.items.findIndex(i => i.value === item.value);
        },
        selectItem(index) {
            const item = this.items[index];
    
            if (item) {
            this.command({ id: item });
            }
        },
    },
  };
</script>

<style lang="scss">
.mention {
  color: var(--app-link-color-hex);
  font-weight: bold;
  background-color: rgba(var(--app-link-color-rgb), 0.2);
  padding: 4px 6px;
  font-size: 80%;
  border-radius: 4px;
}
</style>

<style lang="scss" scoped>
    .highlight {
        background-color: rgba(0, 0, 0, 0.08);
        transition: background-color 0.3s ease;
    }

    .scrollable-list {
        max-height: 200px;
        overflow-y: auto; 
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 5px;
    }
</style>