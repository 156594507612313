<template>
    <div class="user-avatar" v-bind="$attrs" @click="$emit('click')">
        <img :id="`user-avatar-${_uid}`" :width="width" :height="width" class="rounded-circle" :src="userAvatar" :title="userName">
        <b-tooltip v-if="userId" :target="`user-avatar-${_uid}`" placement="bottom" triggers="hover">
            {{ userName }}
        </b-tooltip>
        <div v-if="showLabel" class="user-body ml-1">
            {{ userName }}
        </div>
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
    components: {
    },
    props: {
        userId: String,
        empty: {
            type: String,
            default: '/faces/empty.png'
        },
        width: {
            type: Number | String,
            default: 24
        },
        emptyLabel: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean
        }
    },
    data: () => ({
        image: ''
    }),
    computed: {
        userName() {
            if (!this.userId) return this.emptyLabel;
            return this.$store.getters['users/getNameFromId'](this.userId);
        },
        userAvatar() {
            if (!this.userId) return this.empty;
            return this.$store.getters['users/getAvatarFromId'](this.userId);
        },
    },
    mounted() {
    },
    methods: {
    }
};
</script>
<style lang="scss" scoped>
.user-avatar {
    position: relative;
    display: flex;
    align-items: center;
}
</style>