<template>
  <div class="editor" :class="{ 'with-menu': !hideMenu, 'is-invalid': invalid }">
      <div
        class="menubar is-hidden mb-2"
        :class="{ 'is-focused': !hideMenu || focused }"
        v-if="editor"
        @click.capture.prevent
      >

        <button
          v-if="!textonly"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()"
          :title="$t('commons.editor.command.bold')"
        >
          <icon name="bold" />
        </button>

        <button
          v-if="!textonly"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
          :title="$t('commons.editor.command.italic')"
        >
          <icon name="italic" />
        </button>

        <button
          v-if="!textonly"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()"
          :title="$t('commons.editor.command.strike')"
        >
          <icon name="strike" />
        </button>

        <button
          v-if="!textonly"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()"
          :title="$t('commons.editor.command.underline')"
        >
          <icon name="underline" />
        </button>

        <button
          v-if="!singleline && !textonly && mode !== 'slack'"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          H1
        </button>

        <button
          v-if="!singleline && !textonly && mode !== 'slack'"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          H2
        </button>

        <button
          v-if="!singleline && !textonly && mode !== 'slack'"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          H3
        </button>

        <button
          v-if="!singleline && !textonly"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click="editor.chain().focus().toggleBulletList().run()"
          :title="$t('commons.editor.command.bulletList')"
        >
          <icon name="ul" />
        </button>

        <button
          v-if="!singleline && !textonly"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :title="$t('commons.editor.command.orderedList')"
        >
          <icon name="ol" />
        </button>

        <button
          v-if="!singleline && !textonly && mode !== 'slack'"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('taskList') }"
          @click="editor.chain().focus().toggleTaskList().run()"
          :title="$t('commons.editor.command.taskList')"
        >
          <icon name="task-list" />
        </button>

        <text-editor-link-command v-if="!singleline && !textonly" :editor="editor" @show="linkShow = true" @hide="linkShow = false" />

        <button
          v-if="!singleline && !textonly"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('blockquote') }"
          @click="editor.chain().focus().toggleBlockquote().run()"
          :title="$t('commons.editor.command.blockquote')"
        >
          <icon name="quote" />
        </button>

        <button
          v-if="!singleline && !textonly"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('code') }"
          @click="editor.chain().focus().toggleCode().run()"
          :title="$t('commons.editor.command.code')"
        >
          <icon name="code" />
        </button>

        <button
          v-if="!singleline && !textonly && mode !== 'slack'"
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('codeBlock') }"
          @click="editor.chain().focus().toggleCodeBlock().run()"
          :title="$t('commons.editor.command.codeBlock')"
        >
          <icon name="block_code" />
        </button>

        <button
          v-if="!singleline && !textonly"
          class="menubar__button"
          @click="editor.chain().focus().setHardBreak().run()"
          :title="$t('commons.editor.command.hardbreak')"
        >
          <icon name="hardbreak" />
        </button>

        <button
            class="menubar__button"
            @click="editor.chain().focus().insertContent('@').run()"
            :title="$t('commons.editor.command.mention')"
            v-if="haveMention"
        >
            <icon name="mention" />
        </button>

        <text-editor-variable-command v-if="allowVariables" :editor="editor" :items="variables" />

      </div>
    <editor-content class="editor__content" :editor="editor" />
    <p v-if="showPlaceholder" class="-placeholder" @click="focus">
        {{ placeholder }}
    </p>
  </div>
</template>

<script>
import suggestion from '@/libs/tiptap/mention/suggestion';
import Icon from '@/modules/commons/components/Icon';
// import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { Editor, EditorContent } from '@tiptap/vue-2';
import { Extension } from '@tiptap/core';
// import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Blockquote from '@tiptap/extension-blockquote';
import BulletList from '@tiptap/extension-bullet-list';
import CodeBlock from '@tiptap/extension-code-block';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Bold from '@tiptap/extension-bold';
import Code from '@tiptap/extension-code';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import History from '@tiptap/extension-history';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { SingleLineDocument } from '@/libs/tiptap/singleLineDocument';
import { Variable } from '@/libs/tiptap/variable/variable';
import '@/libs/tiptap/variable/variable.scss';
import TextEditorVariableCommand from './TextEditorVariableCommand';
import TextEditorLinkCommand from './TextEditorLinkCommand';
import CustomMention from '@/libs/tiptap/mention/CustomMention';

export default {
  components: {
    EditorContent,
    TextEditorVariableCommand,
    TextEditorLinkCommand,
    Icon,
  },
  props: {
        value: String | Number,
        placeholder: String,
        mode: {
            type: String,
            required: false,
            default: 'default'
        },
        hideMenu: Boolean,
        textonly: Boolean,
        singleline: Boolean,
        allowVariables: Boolean,
        variables: Array,
        invalid: Boolean,
        disabled: Boolean,
        haveMention: {
            type: Boolean,
            default: false
        },
  },
    data: () => ({
      input: null,
      editor: null,
      focused: false,
      linkShow: false
    }),
    computed: {
        showPlaceholder() {
            return this.hideMenu && this.placeholder?.length && !this.focused && (!this.input?.length || this.input === '<p></p>');
        },
        usersOptions() {
            const users = [...this.$store.getters['users/getAll'] || []].sort((a, b) => {
                return a.lastName - b.lastName;
            }).map(u => ({value: u.id, label: u.firstName && u.lastName ? `${u.firstName} ${u.lastName}` : `${u.email}`, category: { id: 'USER', label: this.$t('playbooks.template.modal.owner.type.user.label')} }));
            return [...users];
        }

    },
    watch: {
        value: function (val) {
            if (this.editor && val !== this.input) {
                this.input = val;
                this.editor?.commands?.setContent(this.input || '', true);
            }
        },
        disabled: function (value) {
            if (!this.editor) return;
            this.editor.setOptions({editable: !value});
        }
    },
    mounted() {
        const self = this;
        this.input = this.value || this.value === false ? this.value : null;
        this.editor = new Editor({
            useBuiltInExtensions: false,
            extensions: this.singleline ? [
                SingleLineDocument,
                Paragraph,
                Text,
                Italic,
                Strike,
                Underline,
                this.haveMention ? CustomMention.configure({
                    usersOptions: this.usersOptions,
                    suggestion: {
                        ...suggestion,
                        items: ({ query }) =>
                        this.usersOptions.filter((item) =>
                            item.label.toLowerCase().includes(query.toLowerCase())
                        ),
                    },
                }) : null,
                this.allowVariables ? Variable.configure({
                    suggestion: {
                        items: () => this.variables
                    }
                }) : null,
                Extension.create({
                    addKeyboardShortcuts() {
                        return {
                        'Alt-Enter'() {
                            return true;
                        },
                        'Cmd-Enter'() {
                            self.$emit('submit');
                            return true;
                        },
                        'Ctrl-Enter'() {
                            self.$emit('submit');
                            return true;
                        },
                        };
                    },
                })
            ] : [
                Document,
                Paragraph,
                Text,
                // StarterKit,
                Blockquote,
                CodeBlock,
                Heading.configure({
                    levels: [1, 2, 3],
                }),
                ListItem,
                BulletList,
                OrderedList,
                TaskList,
                TaskItem.configure({
                  nested: true,
                }),
                // // TodoItem,
                // // TodoList,
                Bold,
                Code,
                Italic,
                Link.configure({
                    openOnClick: false,
                    validate: href => /^https?:\/\//.test(href),
                    HTMLAttributes: {
                        class: 'link-node',
                    },
                }),
                Strike,
                Underline,
                History,
                HardBreak,
                Image,
                Table.configure({
                  resizable: true,
                }),
                TableRow,
                TableHeader,
                TableCell,
                this.haveMention ? CustomMention.configure({
                    usersOptions: this.usersOptions,
                    suggestion: {
                        ...suggestion,
                        items: ({ query }) =>
                        this.usersOptions.filter((item) =>
                            item.label.toLowerCase().includes(query.toLowerCase())
                        ),
                    },
                }) : null,
                this.allowVariables ? Variable.configure({
                    suggestion: {
                        items: () => this.variables
                    }
                }) : null,
                Extension.create({
                    addKeyboardShortcuts() {
                        return {
                        'Alt-Enter'() {
                            self.editor.commands.setHardBreak();
                            return true;
                        },
                        'Cmd-Enter'() {
                            self.$emit('submit');
                            return true;
                        },
                        'Ctrl-Enter'() {
                            self.$emit('submit');
                            return true;
                        },
                        };
                    },
                })
            ],
            // extensions: [
            //     StarterKit
            // ],
            content: this.input || '',
            onUpdate: this.handleChange.bind(this),
            onFocus: this.handleFocus.bind(this),
            onBlur: this.handleBlur.bind(this),
            editable: !this.disabled
        });
    },
    beforeDestroy() {
        this.editor?.destroy();
    },
    methods: {
        focus() {
            if (!this.hideMenu) return;
            this.handleFocus();
            this.editor.chain().focus();
        },
        handleFocus() {
            this.focused = true;
        },
        handleBlur() {
            setTimeout(() => {
                if (this.linkShow) return;
                this.focused = false;
            }, 150);
        },
        handleChange() {
            this.input = this.editor.getHTML();
            this.$emit('change', this.input);
            this.$emit('input', this.input);
        }
    }
  
};
</script>
<style lang="scss">
@import "../../../assets/components/bootstrap4/functions";
@import "../../../assets/components/bootstrap4/variables";

.editor {
    .menubar {
        padding: 0.2rem 0.5rem;
        border-bottom: 1px solid #ced4da;
        flex-wrap: wrap;
    }

    &.with-menu {
        border: 1px solid #ced4da;
        border-radius: 0.3rem;

        .editor__content {
            padding: 0.5rem 1rem;
        }
    }

    &.is-invalid {
        border-color: $form-feedback-invalid-color;
    }

    .link-node {
        font-weight: 600;
        cursor: pointer;
        color: rgba(43, 141, 253, 0.733);
        &:hover {
            text-decoration: underline;
        }
    }
    .-placeholder {
        font-style: italic;
        margin-top: -38px;
    }

    ul[data-type="taskList"] {
        list-style: none;
        padding: 0;

        p {
            margin: 0;
        }

        li {
            display: flex;

            > label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            user-select: none;
            }

            > div {
            flex: 1 1 auto;
            }
        }
    }
}
</style>