var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.items.length > 0
      ? _c(
          "div",
          { staticClass: "scrollable-list" },
          _vm._l(_vm.items, function(item, index) {
            return _c("div", { key: item.value }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-center w-100 px-3 py-2 -pointer mention-list-item",
                  class: { highlight: index === _vm.selectedIndex },
                  on: {
                    click: function($event) {
                      return _vm.selectItem(index)
                    },
                    mouseenter: function($event) {
                      _vm.selectedIndex = index
                    }
                  }
                },
                [
                  _c("user-avatar", {
                    staticClass: "mr-2",
                    attrs: {
                      width: "24",
                      userId: item.value,
                      empty: "/faces/question.png"
                    }
                  }),
                  _vm._v(
                    "\n                " + _vm._s(item.label) + "\n            "
                  )
                ],
                1
              )
            ])
          }),
          0
        )
      : _c("div", [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("commons.editor.mention.nouser")) +
              "\n    "
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }