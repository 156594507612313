var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadMore,
            expression: "loadMore"
          }
        ],
        staticClass: "infinite-scroll-container",
        attrs: {
          "infinite-scroll-disabled": _vm.busy,
          "infinite-scroll-distance": 10
        }
      },
      [
        _vm._l(_vm.notifications, function(notification) {
          return _c(
            "div",
            { key: notification.id },
            [
              _c("notification-list-item", {
                attrs: { notification: notification, show: _vm.showed },
                on: {
                  "update:show": function($event) {
                    _vm.showed = $event
                  },
                  "disabled-popover": _vm.handleDisabledPopover
                }
              })
            ],
            1
          )
        }),
        _vm.busy
          ? _c(
              "div",
              { staticClass: "align-self-center my-2" },
              [
                _c("b-spinner", {
                  staticClass: "mx-auto",
                  attrs: { variant: "primary" }
                })
              ],
              1
            )
          : _vm._e()
      ],
      2
    ),
    _vm.notifications.length === 0
      ? _c(
          "div",
          { staticClass: "no-notification mt-3 p-3" },
          [
            _c("b-icon", { attrs: { icon: "bell-slash-fill" } }),
            _vm._v("\n        " + _vm._s(_vm.noNotificationMessage) + "\n    ")
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }