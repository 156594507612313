import Mention from '@tiptap/extension-mention';
import { mergeAttributes } from "@tiptap/core";

const CustomMention = Mention.extend({
  
    addOptions() {
        return {
            ...this.parent?.(),
            usersOptions: [],
        };
    },

    addAttributes() {
        return {
            ...this.parent?.(),
            mentionId: {
                default: null,
                parseHTML: element => {
                    return element.getAttribute('data-mention');
                },
                renderHTML: attributes => ({
                    'data-mention': attributes.id?.value,
                }),
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span[data-mention]',
            },
        ];
        },

    renderHTML({ node, HTMLAttributes }) {
        const attributes = node.attrs;
        const mentionId = attributes.mentionId;

        if (HTMLAttributes['data-id']) delete HTMLAttributes['data-id'];
        if (!HTMLAttributes['data-mention']) HTMLAttributes['data-mention'] = mentionId;

        let label = this.options.usersOptions.find((user) => user.value === mentionId)?.label || mentionId;

        if (!label) {
            label = attributes?.id?.label;
        }
        return [
            'span',
            mergeAttributes(HTMLAttributes, { class: 'mention' }),
            `@${label}`,
        ];
    },
});

export default CustomMention;
