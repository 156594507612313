var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-toast",
    {
      attrs: {
        id: "ReportMrrToast",
        variant: "info",
        solid: "",
        "no-auto-hide": true,
        visible: _vm.visible
      },
      scopedSlots: _vm._u([
        {
          key: "toast-title",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "d-flex flex-grow-1 align-items-baseline" },
                [
                  _c("strong", { staticClass: "mr-auto" }, [
                    _vm._v(_vm._s(_vm.$t("news.toast.common.feature")))
                  ]),
                  _c("small", { staticClass: "text-muted ml-2 mr-2" }, [
                    _vm._v(_vm._s(_vm.$t("news.ReportMrrToast.description")))
                  ])
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "router-link",
        { attrs: { to: { name: "reportMrr" } } },
        [
          _c("b-img-lazy", {
            attrs: {
              src: "assets/images/news/mrr-report.png",
              width: "500",
              thumbnail: ""
            },
            on: {
              click: function($event) {
                _vm.visible = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }