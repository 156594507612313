import dayjs from 'dayjs';

export function fromAPI(json) {
    if (json.lastTouchTs) json.lastTouchTs = dayjs(json.lastTouchTs).toDate();
    if (json.lastUserTouchTs) json.lastUserTouchTs = dayjs(json.lastUserTouchTs).toDate();
    if (json.lastActivityTs) json.lastActivityTs = dayjs(json.lastActivityTs).toDate();
    if (json.startDate) json.startDate = dayjs(json.startDate).toDate();
    if (json.renewalDate) json.renewalDate = dayjs(json.renewalDate).toDate();
    if (json.endDate) json.endDate = dayjs(json.endDate).toDate();
    if (json.churnAt) json.churnAt = dayjs(json.churnAt).toDate();
    if (json.createdAt) json.createdAt = dayjs(json.createdAt).toDate();
    if (json.updatedAt) json.updatedAt = dayjs(json.updatedAt).toDate();
    if (json.deletedAt) json.deletedAt = dayjs(json.deletedAt).toDate();
    if (json.payment?.ts) json.payment.ts = dayjs(json.payment.ts).toDate();
    if (json.mrr) json.mrr = parseInt(json.mrr, 10);
    if (json.arr) json.arr = parseInt(json.arr, 10);
    if (json.lastCsmTs) json.lastCsmTs = dayjs(json.lastCsmTs).toDate();
    json.tags = json.tags || [];
    json.kpis = json.kpis || {};
    return json;
}

export function toAPI(customer) {
    const json = {...customer};
    if (json.domain) json.domain = json.domain.replace('www.', '');
    if (!json.description) delete json.description;
    if (!json.stage) delete json.stage;
    if (!json.profileId || json.profileId === '') json.profileId = null;
    if (!json.goalId || json.goalId === '') json.goalId = null;
    if (json.team) json.team = JSON.parse(JSON.stringify(json.team));
    delete json.csmScore;
    delete json.csmOwner;
    delete json.autoRenew;
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;
    delete json.deleted;
    delete json.lastTouchTs;
    delete json.lastUserTouchTs;
    delete json.mrr;
    delete json.startDate;
    delete json.endDate;
    delete json.renewalDate;
    delete json.healthScore;
    delete json.lastCsmTs;
    delete json.kpis;
    delete json.children;
    delete json.childrenCount;
    delete json._dataPath;
    return json;
}

export function fromAPIKpi(json) {
    if (json.last_meet) json.last_meet = dayjs(json.last_meet).toDate();
    if (json.next_meet) json.next_meet = dayjs(json.next_meet).toDate();
    if (json.last_sponsor_touch) json.last_sponsor_touch = dayjs(json.last_sponsor_touch).toDate();
    return {
        ...json,
        usage_stick_w: (json.usage_au_7d > 0 && json.usage_au_30d > 0) ? Math.round((json.usage_au_7d / json.usage_au_30d).toFixed(2) * 100) : 0
    };
}

export function getDataPath (data, customersKeys, child, rank = 0) {
    if (!data) return [];
    if (!data.children) data.children = [];
    if (child) {
        data.children.push(child);
        data.childrenCount = data.children.length;
    }
    if (data.parentId && data.parentId !== data.id && rank < 5) {
        return [...getDataPath(customersKeys[data.parentId], customersKeys, data, rank+1), data.id];
    }
    else if (rank >= 5) {
        console.error(`Too much customer parents ${data.id}`);
    }
    return [data.id];
}