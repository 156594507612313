var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-avatar", {
        staticClass: "mr-1 -pointer p-1 app-notification-logo",
        attrs: {
          badge: _vm.hasNewNotifications,
          "badge-variant": "danger",
          id: "notification-avatar-" + _vm._uid,
          variant: "primary",
          size: "m",
          icon: "bell-fill"
        }
      }),
      _c(
        "b-popover",
        {
          staticClass: "popover",
          attrs: {
            target: "notification-avatar-" + _vm._uid,
            placement: "bottomleft"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.onClickOutside,
                  expression: "onClickOutside"
                }
              ],
              staticClass: "p-2"
            },
            [
              _c(
                "header",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center mb-2"
                },
                [
                  _c("h5", { staticClass: "m-0" }, [
                    _vm._v(_vm._s(_vm.$t("notifications.popover.title")))
                  ]),
                  _c("table-settings-notification", {
                    attrs: { show: _vm.tableSettingShow },
                    on: {
                      "update:show": function($event) {
                        _vm.tableSettingShow = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "main",
                [
                  _c(
                    "b-tabs",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-2 d-flex flex-row justify-content-center align-items-center"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t("notifications.tabs.new")
                                          ) +
                                          " \n                                "
                                      ),
                                      _vm.userStats.new
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "notification-number ml-1"
                                            },
                                            [_vm._v(_vm._s(_vm.userStats.new))]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("notification-list-view", {
                            attrs: {
                              notificationType: _vm.notificationsType.NEW,
                              notifications: _vm.newNotifications,
                              noNotificationMessage: _vm.$t(
                                "notifications.popover.new.empty"
                              ),
                              show: _vm.notificationListShow
                            },
                            on: {
                              "update:show": function($event) {
                                _vm.notificationListShow = $event
                              },
                              "disabled-popover": _vm.disabledPopover
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "px-2" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t("notifications.tabs.all")
                                        ) +
                                        "\n                            "
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("notification-list-view", {
                            attrs: {
                              notificationType: _vm.notificationsType.ALL,
                              notifications: _vm.allNotifications,
                              noNotificationMessage: _vm.$t(
                                "notifications.popover.all.empty"
                              ),
                              show: _vm.notificationListShow
                            },
                            on: {
                              "update:show": function($event) {
                                _vm.notificationListShow = $event
                              },
                              "disabled-popover": _vm.disabledPopover
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "px-2" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t("notifications.tabs.archived")
                                        ) +
                                        "\n                            "
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("notification-list-view", {
                            attrs: {
                              notificationType: _vm.notificationsType.ARCHIVED,
                              notifications: _vm.archivedNotifications,
                              noNotificationMessage: _vm.$t(
                                "notifications.popover.archived.empty"
                              ),
                              show: _vm.notificationListShow
                            },
                            on: {
                              "update:show": function($event) {
                                _vm.notificationListShow = $event
                              },
                              "disabled-popover": _vm.disabledPopover
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }