var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("div", { staticClass: "header-btn-lg pr-0" }, [
        _c("div", { staticClass: "widget-content p-0" }, [
          _c(
            "div",
            { staticClass: "widget-content-wrapper" },
            [
              _c(
                "div",
                { staticClass: "widget-content-left" },
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        "toggle-class": "p-0 mr-2",
                        "menu-class": "dropdown-menu-lg",
                        variant: "link",
                        right: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "icon-wrapper icon-wrapper-alt"
                                },
                                [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true }
                                      }
                                    ],
                                    staticClass: "rounded-circle",
                                    attrs: {
                                      width: "42",
                                      src: _vm.avatar,
                                      alt: "",
                                      title: _vm.userName
                                    }
                                  }),
                                  _vm.badges
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "widget-content-left badges"
                                        },
                                        [
                                          _vm.badges.expert
                                            ? _c("img", {
                                                attrs: {
                                                  src: _vm.expertLogo.path,
                                                  title: _vm.expertLogo.alt,
                                                  alt: _vm.expertLogo.alt
                                                }
                                              })
                                            : _vm.badges.advanced
                                            ? _c("img", {
                                                attrs: {
                                                  src: _vm.advancedLogo.path,
                                                  title: _vm.advancedLogo.alt,
                                                  alt: _vm.advancedLogo.alt
                                                }
                                              })
                                            : _vm.badges.essential
                                            ? _c("img", {
                                                attrs: {
                                                  src: _vm.essentialLogo.path,
                                                  title: _vm.essentialLogo.alt,
                                                  alt: _vm.essentialLogo.alt
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "dropdown-item",
                          attrs: { type: "button", tabindex: "0" },
                          on: { click: _vm.handleMyProfile }
                        },
                        [_vm._v(_vm._s(_vm.$t("commons.header.myprofile")))]
                      ),
                      _c("div", {
                        staticClass: "dropdown-divider",
                        attrs: { tabindex: "-1" }
                      }),
                      _vm.user
                        ? _c(
                            "button",
                            {
                              staticClass: "dropdown-item",
                              attrs: { type: "button", tabindex: "1" },
                              on: { click: _vm.handleGoToDoc }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("global.link.documentation.title")
                                )
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.user
                        ? _c(
                            "button",
                            {
                              staticClass: "dropdown-item",
                              attrs: { type: "button", tabindex: "1" },
                              on: { click: _vm.handleFeatureRequest }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("global.link.feature-request.title")
                                )
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.user
                        ? _c(
                            "button",
                            {
                              staticClass: "dropdown-item",
                              attrs: { type: "button", tabindex: "1" },
                              on: { click: _vm.handleRoadmap }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("global.link.roadmap.url.title"))
                              ),
                              _c("b-icon", {
                                staticClass: "ml-2",
                                attrs: { icon: "box-arrow-up-right" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", {
                        staticClass: "dropdown-divider",
                        attrs: { tabindex: "-1" }
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "dropdown-item",
                          attrs: { type: "button", tabindex: "2" },
                          on: { click: _vm.handleLogout }
                        },
                        [_vm._v(_vm._s(_vm.$t("commons.header.logout")))]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "widget-content-left  ml-3 header-user-info" },
                [
                  _c("div", { staticClass: "widget-heading" }, [
                    _vm._v(_vm._s(_vm.userName))
                  ]),
                  _vm.user
                    ? _c("div", { staticClass: "widget-subheading" }, [
                        _vm._v(_vm._s(_vm.subHeading))
                      ])
                    : _vm._e()
                ]
              ),
              _c("notification-popover", { staticClass: "ml-2" })
            ],
            1
          )
        ])
      ]),
      _vm.user
        ? _c("profile-modal", {
            attrs: { user: _vm.user },
            on: { close: _vm.onCloseProfile }
          })
        : _vm._e(),
      _vm.user
        ? _c("feature-request-modal", { attrs: { user: _vm.user } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }